var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"productsList",class:_vm.hasFeaturedProducts === false ? 'notFeaturedProducts' : '',attrs:{"id":"productList"}},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInDown delay-2s"}},_vm._l((_vm.products),function(product){return _c('div',{staticClass:"descriptionCollectionContent "},_vm._l((product.filhos),function(collection){return (collection.id==_vm.selectedCollection)?_c('div',[_c('div',{staticClass:"categoria"},[_vm._v(_vm._s(product.title))]),_c('div',{staticClass:"titulo1"},[_vm._v(_vm._s(collection.firstname))]),_c('div',{staticClass:"titulo2"},[_vm._v(_vm._s(collection.secondname))]),_c('div',{staticClass:"colecao",domProps:{"innerHTML":_vm._s(collection.descricao)}})]):_vm._e()}),0)}),0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"id":"totop"}},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInDown","leave-active-class":"animated slideOutDown"}}),_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInUp delay-2s"}},[_c('div',{staticClass:"filters col-md-3"},[_c('div',{staticClass:"contentorFiltros"},[_c('div',{staticClass:"tituloCollections",domProps:{"innerHTML":_vm._s(_vm.$t('collections'))}}),_vm._l((_vm.products),function(product,index){return _c('ul',{key:"filterCollection",ref:"menu",refInFor:true,class:'cat-' + product.id + ' collectionMenu'},_vm._l((product.filhos),function(collection){return _c('li',{key:collection.id,staticClass:"filters__item",class:collection.id == _vm.selectedCollection
									? 'filters__item active'
									: 'filters__item'},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('header'),expression:"'header'"}],attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.filterByCollection(collection.id)}}},[_vm._v(_vm._s(collection.title))])])}),0)}),_c('ul',{staticClass:"colorFilters"},[_c('div',{staticClass:"tituloCollections",domProps:{"innerHTML":_vm._s(_vm.$t('colors'))}}),_vm._l((_vm.availableColors),function(color){return _c('li',{key:color.id},[_c('a',{class:_vm.getColorStatus(color.id),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.filterByColor(color.id)}}},[_c('span',{staticClass:"color",style:('background-color:' + color.hexa)}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(color.name))])])])}),_c('a',{staticClass:"clear_filters",attrs:{"href":"javascript:void(0);"},domProps:{"innerHTML":_vm._s(_vm.$t('clear_filters'))},on:{"click":function($event){return _vm.unfilterByColor()}}})],2)],2)])]),_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInUp delay-2s"}},_vm._l((_vm.products),function(product,index){return _c('div',{staticClass:"col-12 col-md-9 productsContainer row",class:_vm.hasFeaturedProducts === true ? 'featuredCenter' : '',attrs:{"itemscope":"","itemtype":"http://schema.org/ItemList"}},[_vm._l((product.filhos),function(collection,index){return (collection.id==_vm.selectedCollection)?[_c('link',{attrs:{"itemprop":"url","href":'/bloco-b/category/' + _vm.selectedCategory + '/collection/' + _vm.selectedCollection}}),(_vm.hasFeaturedProducts)?_c('span',{staticClass:"d-none",attrs:{"itemprop":"numberOfItems"}},[_vm._v(_vm._s(_vm.productsPerPage))]):_vm._e(),(!_vm.selectedColors.length)?[_vm._l((collection.filhos),function(child,index){return [_c('router-link',{key:child.id,staticClass:"rellaxProduct product col-12 col-lg-6 col-xl-4 ",attrs:{"itemprop":"itemListElement","itemscope":"","itemtype":"http://schema.org/Product","to":{
							path:
							'/bloco-b/category/' +
							_vm.selectedCategory +
							'/collection/' +
							_vm.selectedCollection +
							'/id/' +
							child.id +
							'/name/' +
							child.firstname +
							'-' +
							child.secondname,
						},"data-rellax-speed":_vm.getDataSpeed(index)}},[_c('meta',{attrs:{"itemprop":"position","content":index}}),_c('a',{attrs:{"href":'/bloco-b/category/' +
									_vm.selectedCategory +
									'/collection/' +
									_vm.selectedCollection +
									'/id/' +
									child.id +
									'/name/' +
									child.firstName +
									'-' +
									child.secondName,"itemprop":"url"}}),_c('meta',{attrs:{"itemprop":"sku","content":child.id}}),_c('div',{attrs:{"itemprop":"offers","itemtype":"https://schema.org/Offer","itemscope":""}},[_c('span',{attrs:{"itemprop":"priceCurrency","content":"EUR"}}),_c('link',{attrs:{"itemprop":"availability","href":"https://schema.org/InStock"}})]),_c('div',{staticClass:"containerImage"},[_c('img',{staticClass:"productImage",attrs:{"src":child.imagem_do_produto,"width":"300","height":"300","alt":child.title,"itemprop":"image"}})]),_c('p',{staticClass:"productName",attrs:{"itemprop":"name"}},[_c('mark',[_c('span'),_vm._v(" "+_vm._s(child.firstname)+" "),_c('br'),_vm._v(" "+_vm._s(child.secondname)+" ")])]),(_vm.hasFeaturedProducts)?_c('p',{staticClass:"categoryName"},[_vm._v(_vm._s(_vm.getCategory(collection).title))]):_vm._e()])]})]:[_vm._l((collection.filhos),function(child,index){return [_vm._l((_vm.selectedColors),function(color,index2){return [(color==child.cor)?_c('div',{staticClass:"rellaxProduct product col-12 col-lg-6 col-xl-4",attrs:{"data-rellax-speed":_vm.getDataSpeed(index)}},[_c('router-link',{key:child.id,staticClass:" ",attrs:{"itemprop":"itemListElement","itemscope":"","itemtype":"http://schema.org/Product","to":{
							path:
							'/bloco-b/category/' +
							_vm.selectedCategory +
							'/collection/' +
							_vm.selectedCollection +
							'/id/' +
							child.id +
							'/name/' +
							child.firstname +
							'-' +
							child.secondname,
						}}},[_c('meta',{attrs:{"itemprop":"position","content":index}}),_c('a',{attrs:{"href":'/bloco-b/category/' +
									_vm.selectedCategory +
									'/collection/' +
									_vm.selectedCollection +
									'/id/' +
									child.id +
									'/name/' +
									child.firstName +
									'-' +
									child.secondName,"itemprop":"url"}}),_c('meta',{attrs:{"itemprop":"sku","content":child.id}}),_c('div',{attrs:{"itemprop":"offers","itemtype":"https://schema.org/Offer","itemscope":""}},[_c('span',{attrs:{"itemprop":"priceCurrency","content":"EUR"}}),_c('link',{attrs:{"itemprop":"availability","href":"https://schema.org/InStock"}})]),_c('div',{staticClass:"containerImage"},[_c('img',{staticClass:"productImage",attrs:{"src":child.imagem_do_produto,"width":"300","height":"300","alt":child.title,"itemprop":"image"}})]),_c('p',{staticClass:"productName",attrs:{"itemprop":"name"}},[_c('mark',[_c('span'),_vm._v(" "+_vm._s(child.firstname)+" "),_c('br'),_vm._v(" "+_vm._s(child.secondname)+" ")])]),(_vm.hasFeaturedProducts)?_c('p',{staticClass:"categoryName"},[_vm._v(_vm._s(_vm.getCategory(collection).title))]):_vm._e()])],1):_vm._e()]})]})]]:_vm._e()})],2)}),0)],1)]),_c('transition',{attrs:{"enter-active-class":"animated slideInRight faster","leave-active-class":"animated slideOutRight faster"}},[(_vm.showLoginForm)?_c('ModalLogin',{on:{"close":function($event){_vm.showLoginForm = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }