<template>
	<div id="company" class="pageContainer">
		

		<ArticleParallax>
			<div class="pageContentText col-12 col-md-5 order-2 order-md-2">
				<h1 v-html="company.title"></h1>
				<p v-html="company.content"></p>

				<!-- a href="" class="basicLink"><arrowRightIcon />{{ $t("readlink") }}</!-->
			</div>
		</ArticleParallax>

		
	</div>
</template>

<script>
	import ArticleParallax from "@/components/ArticleParallax";

	export default {
		name: "company",
		components: {
			ArticleParallax
		},
		data() {
			return {				
				newsAmount: 6,
				hasPaging: false,
				hasLink: true,
				company: "",
				gallery1: [],
				gallery2: [],
				slidergallery: [],
				hasFeaturedProducts: true,
				productsPerPage: 6,
				bannerLoaded: false,
				hasJsonData: false
			};
		},
		methods: {
			notifyFinishLoad() {
				if (!(this.hasJsonData)) return;
				this.$eventBus.$emit("pageFinishLoad", true);
			}
		},
		mounted() {
			this.$eventBus.$on("componentFinishLoad", data => {
				if (data == "bannerLoaded") {
					this.bannerLoaded = true;
					this.notifyFinishLoad();
					return;
				}
			});

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/homepage2"
				)
				.then(response => {
					this.company = response.data;
					this.gallery1 = response.data.gallery1;
					
					

					this.hasJsonData = true;
					this.notifyFinishLoad();
				});
		}
	};
</script>
<style lang="scss">
	.basicLink {
		font-family: "Oswald", sans-serif;
		font-size: 15px;
		font-weight: 300;
		letter-spacing: 2px;
		text-decoration: none;
		text-transform: uppercase;
		color: #000;
		position: relative;
		margin-top: 5%;

		&::after {
			position: absolute;
			top: 11px;
			right: -60px;
			content: "";
			display: block;
			height: 1px;
			width: 40px;
			background-color: #000;
		}

		.arrowSlimIcon {
			position: absolute;
			top: -3px;
			right: -60px;

			svg line,
			svg path {
				stroke: #000;
			}
		}

		&:hover {
			color: #000;

			&::after {
				background-color: #b76646;
			}
			svg line,
			svg path {
				stroke: #b76646;
			}
		}
	}
	@media (max-width: 1200px){
		#company .parallaxGroup1 .imageParallax2{
			top: 40%;
		}
		#company .parallaxContainer {
			padding-top: 0;
		}
	}
	@media (max-width: 950px){
		#company .parallaxGroup1 .imageParallax2{
			top: 30%;
		}
		#company .parallaxContainer {
			padding-top: 0;
		}
	}
	@media (max-width: 812px){
		.titlehomefeatured {
			padding-top: 150px;
		}
		#company .pageContentText {
			flex: 100%;
			max-width: 100%;
		}
		#company .order-sm-2 {
			order: 3 !important; 
			margin-bottom: 160px;
		}
		#company .pageContentText{
			margin-top: 0px ;
			padding: 100px ;
			padding-bottom: 00px ;
		}
		#company .parallaxGroup1 .imageParallax1,
		#company .parallaxGroup1 .imageParallax2,
		#company .parallaxGroup2 img{
			transform: none !important;
		}
		
		#company .parallaxGroup1 img, #company .parallaxGroup2 img {
			position: initial;
		}
		#company .parallaxGroup2 img {
			padding-bottom: 20px;
		}
		#company .parallaxGroup2  {
			height: auto;
		}
		#company .parallaxGroup1 .col-sm-6.imageParallax1 {
			flex: 0 0 50%;
			max-width: 100% ;
		}
		#company .parallaxGroup1 img.imageParallax2{
			float: none;
		}
		#company .parallaxGroup1 .rellaxArticle.col-9 {
			max-width: 100%;
			margin-top: 10px;
		}
		#company .rellaxArticle.col-md-6, #company .rellaxArticle.col-md-5, #company .parallaxGroup2 .rellaxArticle.col-9 { 
			max-width: 66%;
		}
		#company .h2.pageTitleh2{
			padding-top: 60px;
		}
		#company .wrapper .masonry{
			display: block;
		}
		#company .wrapper .masonry[page="2"] {
			max-height: initial;
		}
		#company .wrapper a {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto 0;
		}
		#company .parallaxContainer {
			padding-right: 0;
		}
		#company .parallaxGroup2 {
			text-align: center;
		}
	}
</style>
