<template>
	<div id="bexplorepage" class="bexplore container">
		<ArticleParallax>
			<div class="pageContentText col-12 col-md-5 order-md-2">
				<h1 v-html="bexplore.title"></h1>
				<p v-html="bexplore.content"></p>
			</div>
		</ArticleParallax>

		<PortfolioMansory>
			<h1 class="pageTitleh2 h2">
				{{ bexplore.title_images }}				
			</h1>
		</PortfolioMansory>
	</div>
</template>

<script>
	import ArticleParallax from "@/components/ArticleParallax";
	import PortfolioMansory from "@/components/PortfolioMansory";

	export default {
		name: "bexplorepage",
		components: {
			ArticleParallax,
			PortfolioMansory,
			
		},
		data() {
			return {
				bexplore: "",
				mansoryLoaded: false,
				hasJsonData: false,
				gallery1: [],
				gallery2: [],
			};
		},
		methods: {
			notifyFinishLoad() {
				if (!(this.mansoryLoaded && this.hasJsonData)) return;
				this.$eventBus.$emit("pageFinishLoad", true);
			}
		},
		mounted() {
			this.$eventBus.$on("componentFinishLoad", data => {
				if (data == "mansoryLoaded") {
					this.mansoryLoaded = true;
					this.notifyFinishLoad();
					return;
				}
			});

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/b-explore2"
				)
				.then(response => {
					this.bexplore = response.data;
					this.$eventBus.$emit("mansoryFinishLoad", response.data.mansory);
					this.gallery1 = response.data.gallery1;
					this.hasJsonData = true;
					this.notifyFinishLoad();
				});
		},
		beforeDestroy() {
			this.$eventBus.$off("componentFinishLoad");
		}
	};
</script>
<style lang="scss">
	// #bexplorepage {
	// 	padding-top: 120px;
	// }
	#bexplorepage .parallaxContainer {
		min-height: calc(100vh + 290px);
		padding-top: 150px; 
		position: relative;
		z-index: 0;
		overflow:hidden;

		&:after{
			position: absolute;
			left: 0; bottom: 0;
			right: 0;
			content: '';
			display: block;
			z-index: 2;
			background: linear-gradient(rgba(240, 240, 240, 0) 0%, #f0f0f0 100%);
			height: 130px;
		}
	}

	#bexplorepage .parallaxGroup1,
	#bexplorepage .parallaxGroup2{

		img{
			width: 100%;
			height: auto;
			margin: 0 auto;
			position: absolute;

			-webkit-filter:     grayscale(100%);
			-moz-filter:        grayscale(100%);
			filter:             grayscale(100%);
			will-change: filter;
			-webkit-transition:     filter .3s cubic-bezier(.4,1.03,.83,.56);
			-moz-transition:        filter .3s cubic-bezier(.4,1.03,.83,.56);
			-o-transition:          filter .3s cubic-bezier(.4,1.03,.83,.56);
			transition:             filter .3s cubic-bezier(.4,1.03,.83,.56);

			&:hover{
				-webkit-filter:     grayscale(0%);
				-moz-filter:        grayscale(0%);
				filter:             grayscale(0%);
			}
		}
	}

	#bexplorepage .parallaxGroup1{

		.imageParallax1{
			z-index: 1;
			right: 10%;
			top: 40%;
			height: 500px;
			object-fit: cover;
		}

		.imageParallax2{
			z-index: 3;
			top: 80%; 
			left: 10%;
			height: 450px;
			object-fit: cover;
		}

		.imageParallax3{
			z-index: 2;
			top: 20%;
			left: 25%;
		}
	}

	#bexplorepage .parallaxGroup2{
		overflow: hidden;
		position: relative;
		height: 1800px;
		margin-top: 400px;
		background: #F0F0F0;

		& .imageParallax3{
			top: 0;
			right: 20%;
			z-index: 0;
		}

		& .imageParallax4{
			top: 60%;
			left: 10%;
			z-index: 1;
		}

		& .imageParallax5{
			top: 10%; 
			right: 0;
			z-index: 1;
		}

		& .imageParallax6{
			top: 47%; 
			left: 27%;
			z-index: 0;
		}

		& .imageParallax7{
			top: 115%; 
			left: 0;
			z-index: 1;
		}
	}

	#bexplorepage .pageContentText { 
    z-index: 2;
    margin-top: 40vh; 
    padding-top: 150px;
    background: rgb(240,240,240);
    background: linear-gradient(180deg, rgba(240,240,240,0) 0%, rgba(240,240,240,1) 10%, rgba(240,240,240,1) 90%, rgba(240,240,240,0) 100%);

        & h1{
            font-size: 40px;
            position: relative;

            &:after{
                content: '';
                position: absolute;
                top: 30px;
                left: -200px;
                background: #C47C5A;
                width: 140px;
                height: 2px;
            }
        }

        p{ padding: 30px 0; }
        .quote {padding-left: 5%; font-style: italic;}
    }

	@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .bexplore.container {
			max-width: 1140px;
		}
	}

	@media (max-width: 812px) {

		#bexplorepage .pageContentText{
			margin-top: 150px;
		}

		#bexplorepage .parallaxGroup1 {
			img{
				/*position: initial;*/
				padding-top: 10%;
			}
			img.imageParallax2 { float: right; }
		}

		#bexplorepage .parallaxGroup2{
			margin-top: 240px;
			height: 1600px;

			& .imageParallax3{
				top: 0;
				right: 20%;
				z-index: 2;
			}

			& .imageParallax4{
				top: 720px;
				left: -10%;
				z-index: 1;
			}

			& .imageParallax5{
				right: 30%;
				z-index: 0;
			}

			& .imageParallax6{
				top: 900px; 
				right: 0;
			}

			& .imageParallax7{
				top: 1980px; 
				left: 0;
			}
		}
	}
	@media (max-width: 767px) {
		#bexplorepage .pageContentText{
			margin-top: 150px;
		}
		#bexplorepage .parallaxGroup1 .imageParallax3{
			display: none;
		}
		#bexplorepage .parallaxGroup1 .imageParallax1,
		#bexplorepage .parallaxGroup1 .imageParallax2{
			transform: none !important;
		}
		#bexplorepage .parallaxGroup1 img {
			position: initial;
		}
		#bexplorepage .parallaxGroup1 img.imageParallax2{
			float: none;
		}
		#bexplorepage .rellaxArticle.col-9 {
			max-width: 100%;
		}
		#bexplorepage .h2.pageTitleh2{
			padding-top: 60px;
		}
		#bexplorepage .wrapper .masonry{
			display: block;
		}
		#bexplorepage .wrapper .masonry[page="2"] {
			max-height: initial;
		}
		#bexplorepage .wrapper a {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto 0;
		}
		#bexplorepage {
			margin-bottom: 200px;
		}
	}

</style>
